import React, { useEffect, useState } from "react";
import axios from "axios";
import Calendar from "./Calender";

// const results = {
//   calendar_months: [
//     {
//       abbr_name: "Nov",
//       day_names: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
//       days: [
//         {
//           available: false,
//           date: "2023-10-29",
//           available_for_checkin: true,
//           price: {
//             date: "2023-10-29",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-10-30",
//           available_for_checkin: true,
//           price: {
//             date: "2023-10-30",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-10-31",
//           available_for_checkin: false,
//           price: {
//             date: "2023-10-31",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-01",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-01",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-02",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-02",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-03",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-03",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-04",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-04",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-05",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-05",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-06",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-06",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-07",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-07",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-08",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-08",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-09",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-09",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-10",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-10",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-11",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-11",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-12",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-12",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-13",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-13",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-14",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-14",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-15",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-15",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-16",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-16",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-17",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-17",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-18",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-18",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-19",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-19",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-20",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-20",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-21",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-21",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-22",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-22",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-23",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-23",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-24",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-24",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-25",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-25",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-26",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-26",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-27",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-27",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-28",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-28",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-29",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-29",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-30",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-30",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-12-01",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-01",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-02",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-02",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//       ],
//       dynamic_pricing_updated_at: "2018-02-19T08:05:53+00:00",
//       listing_id: 18951965,
//       month: "11",
//       name: "November",
//       year: 2023,
//     },
//     {
//       abbr_name: "Dec",
//       day_names: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
//       days: [
//         {
//           available: true,
//           date: "2023-11-26",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-26",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-27",
//           available_for_checkin: true,
//           price: {
//             date: "2023-11-27",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-11-28",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-28",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-29",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-29",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-11-30",
//           available_for_checkin: false,
//           price: {
//             date: "2023-11-30",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-12-01",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-01",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-02",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-02",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-03",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-03",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-04",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-04",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-05",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-05",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-06",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-06",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-07",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-07",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-08",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-08",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-09",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-09",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-10",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-10",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-11",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-11",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-12",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-12",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-13",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-13",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-14",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-14",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-15",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-15",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-16",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-16",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-17",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-17",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-18",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-18",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-19",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-19",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-20",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-20",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-21",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-21",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-22",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-22",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-23",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-23",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-24",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-24",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-25",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-25",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-26",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-26",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-12-27",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-27",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-12-28",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-28",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: false,
//           date: "2023-12-29",
//           available_for_checkin: false,
//           price: {
//             date: "2023-12-29",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-30",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-30",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2023-12-31",
//           available_for_checkin: true,
//           price: {
//             date: "2023-12-31",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-01",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-01",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-02",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-02",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-03",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-03",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-04",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-04",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-05",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-05",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//         {
//           available: true,
//           date: "2024-01-06",
//           available_for_checkin: true,
//           price: {
//             date: "2024-01-06",
//             is_price_upon_request: null,
//             local_adjusted_price: null,
//             local_currency: null,
//             local_price: null,
//             native_adjusted_price: 55,
//             native_currency: "EUR",
//             native_price: 55,
//             type: "default",
//             local_price_formatted: "$",
//           },
//           min_nights: 2,
//           max_nights: 8,
//         },
//       ],
//       dynamic_pricing_updated_at: "2018-02-19T08:05:53+00:00",
//       listing_id: 18951965,
//       month: "12",
//       name: "December",
//       year: 2023,
//     },
//   ],
// };

interface Price {
  date: string;
  is_price_upon_request: null;
  local_adjusted_price: null;
  local_currency: null;
  local_price: null;
  native_adjusted_price: number;
  native_currency: string;
  native_price: number;
  type: string;
  local_price_formatted: string;
}

interface CalendarData {
  available: boolean;
  date: string;
  available_for_checkin: boolean;
  min_nights: number;
  max_nights: number;
  price: Price;
}

interface CalendarMonth {
  abbr_name: string;
  name: string;
  day_names: string[];
  month: string;
  dynamic_pricing_updated_at: string;
  listing_id: number;
  year: number;
  days: CalendarData[];
}

const CalenderComponent: React.FC = () => {
  const [data, setData] = useState<CalendarMonth[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const year = new Date().getFullYear();
        const options = {
          method: "get",
          url: "https://airbnb13.p.rapidapi.com/calendar",
          params: {
            room_id: "625517496214915194",
            currency: "USD",
            year,
            count: "4",
          },
          headers: {
            "X-RapidAPI-Key": process.env.REACT_APP_RAPID_KEY,
            "X-RapidAPI-Host": "airbnb13.p.rapidapi.com",
          },
        };

        const response = await axios(options);

        setData(response.data.results.calendar_months);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, []);

  return (
    <div className="calender-container">
      {data.map((r) => (
        <Calendar key={`${r.month}`} results={r} />
      ))}
    </div>
  );
};

export default CalenderComponent;
